import React from 'react'
import PropTypes from 'prop-types'
import '../scss/signup-banner.scss'
import CTAButton from '../common/CTAButton'
import { Link } from 'gatsby'

export default function SignupBanner(props) {
  const { signupInfo } = props
  return (
    <React.Fragment>
      <div className="sb-mobile-header">
        <img src="/img/koble/koble_logo_white_new.png" alt="logo" />
      </div>
      <div className="d-flex signup-banner-container column-reverse-in-mobile">
        <div className="w-50 w-m-100 text-container">
          <img
            src="/img/koble/koble_logo_white_new.png"
            alt="logo"
            className="logo"
          />
          {signupInfo.map((item, index) => {
            return (
              <div className="d-flex" key={index}>
                <div className="me-4 sb-tick-icon">
                  <img src="/img/koble/green_tick.svg" alt="tick" />
                </div>
                <div>
                  <div className="mb-2 sb-heading">{item.heading}</div>
                  <div className="sb-description">{item.description}</div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="w-50 w-m-100 create-account">
          <img
            src="/img/koble/still-1.png"
            className="w-100 h-100"
            alt="signup"
          />
          <div className="ca-card">
            <div className="ca-title">Create an account</div>
            <div className="ca-signup-with-google">
              <CTAButton
                type="cta"
                text="Sign up with Google"
                leftIcon="img/koble/google_icon.png"
                rightIcon="img/koble/arrow_icon_straight.png"
                link="https://api.koble.ai/auth/google-investor?with-email=1&redirect_url=https://investor.koble.ai/"
              />
            </div>
            <div className="ca-signup-with-email">
              <CTAButton
                type="cta"
                text="Sign up with email"
                bgColor="#6E6BFF"
                link="https://investor.koble.ai/signup?with-email=1"
                rightHoverIcon="/img/koble/arrow_purple_icon.svg"
                rightIcon="img/koble/arrow_icon_straight.png"
              />
            </div>
            <div className="ca-login">
              Already have an account?{' '}
              <Link to="/login">
                <span>Login</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

SignupBanner.propTypes = {
  height: PropTypes.number,
  signupBanner: PropTypes.object
}
