import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SignupBanner from '../components/koble/SignupBanner'

// eslint-disable-next-line
export const SignupPageTemplate = ({ signupInfo }) => {
  return (
    <div>
      <SignupBanner signupInfo={signupInfo} />
    </div>
  )
}

SignupPageTemplate.propTypes = {
  signupInfo: PropTypes.array
}

const SignupPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <SignupPageTemplate signupInfo={post.frontmatter.signupInfo} />
    </Layout>
  )
}

SignupPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default SignupPage

export const signupPageQuery = graphql`
  query SignupPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        signupInfo {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH, height: 770)
            }
          }
        }
      }
    }
  }
`
